export const Catalog = () => import('./../../components/content/Catalog.vue')
export const Collapse = () => import('./../../components/content/Collapse.vue')
export const CollapseList = () => import('./../../components/content/CollapseList.vue')
export const Group = () => import('./../../components/content/Group.vue')
export const Icon = () => import('./../../components/content/Icon.vue')
export const ProseA = () => import('./../../components/content/ProseA.vue')
export const ProseBlockquote = () => import('./../../components/content/ProseBlockquote.vue')
export const ProseImg = () => import('./../../components/content/ProseImg.vue')
export const ProseOl = () => import('./../../components/content/ProseOl.vue')
export const ProseTable = () => import('./../../components/content/ProseTable.vue')
export const Text = () => import('./../../components/content/Text.vue')
export const globalComponents: string[] = ["SvgoBlogDay","SvgoBlogNight","SvgoError","SvgoLandingReshapeCard","SvgoLogoAbbreviation","SvgoLogoBlog","SvgoLogoFull","SvgoLogoHorizontal","SvgoLogoMission","SvgoLogoName","SvgoLogoReshape","SvgoPlaceholder","SvgoSimpleArrow","SvgoSimpleAuth","SvgoSimpleCaret","SvgoSimpleCart","SvgoSimpleCheck","SvgoSimpleCheckSolid","SvgoSimpleChevron","SvgoSimpleChevronDown","SvgoSimpleChevronLeft","SvgoSimpleChevronRight","SvgoSimpleClose","SvgoSimpleEdit","SvgoSimpleInstagram","SvgoSimpleMastercard","SvgoSimpleMinus","SvgoSimpleMir","SvgoSimplePlus","SvgoSimpleSister","SvgoSimpleStar","SvgoSimpleTelegram","SvgoSimpleUser","SvgoSimpleVisa","SvgoSimpleVk","SvgoSimpleYoutube","SvgoSubscribeStep1","SvgoSubscribeStep2","SvgoSubscribeStep3","SvgoSubscribeStep4","SvgoSuccess","ProseCode","ProseEm","ProseH1","ProseH2","ProseH3","ProseH4","ProseH5","ProseH6","ProseHr","ProseLi","ProseP","ProsePre","ProseScript","ProseStrong","ProseTbody","ProseTd","ProseTh","ProseThead","ProseTr","ProseUl"]
export const localComponents: string[] = ["Catalog","Collapse","CollapseList","Group","Icon","ProseA","ProseBlockquote","ProseImg","ProseOl","ProseTable","Text"]